// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoML from "assets/images/logos/music-light-1.svg";

const date = new Date().getFullYear();

export default {
	brand: {
		name: "MusicLight",
		image: logoML,
		route: "/",
	},
	socials: [
		{
			icon: <FacebookIcon/>,
			link: "https://www.facebook.com/profile.php?id=100091905194851&mibextid=LQQJ4d",
		},
		{
			icon: <InstagramIcon/>,
			link: "https://instagram.com/musiclightofficial",
		},
		{
			icon: <LinkedInIcon/>,
			link: "https://www.linkedin.com/company/musiclight-inc/",
		},
	],
	menus: [
		{
			name: "company",
			items: [{name: "about us", route: "/"}],
		},
		{
			name: "resources",
			items: [
				{name: "lessons", route: "/"},
				{name: "illustrations", route: "/"},
			],
		},
		{
			name: "help & support",
			items: [
				{name: "contact us", route: "/"},
				{name: "knowledge center", route: "/"},
				{name: "sponsorships", route: "/"},
			],
		},
		{
			name: "legal",
			items: [
				{name: "terms & conditions", route: "/"},
				{name: "privacy policy", route: "/"},
				{name: "licenses (EULA)", route: "/"},
			],
		},
	],
	copyright: (
		<MKTypography variant="button" fontWeight="regular">
			All rights reserved. Copyright &copy; {date} MusicLight 501(c)(3)
		</MKTypography>
	),
};
