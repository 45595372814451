// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import {useMediaQuery} from "@mui/material";

function DefaultReviewCard({ color, image, name, review }) {
  return (
    <MKBox
      variant={color === "transparent" ? "contained" : "gradient"}
      bgColor={color}
      borderRadius="xl"
      shadow={color === "transparent" ? "none" : "md"}
      p={3}
    >
      {image && (
        <MKAvatar
          src={image}
          alt={name}
          variant="rounded"
          size="lg"
          shadow="md"
          sx={{ mt: -5, mb: 1 }}
        />
      )}
      <MKTypography
        variant="body1"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        &quot;{review}&quot;
      </MKTypography>
      <MKBox lineHeight={1} textAlign="right">
        <MKTypography
          display="block"
          variant={image ? "button" : "h5"}
          fontWeight="bold"
          color={
            color === "transparent" || color === "light" ? "dark" : "white"
          }
          mb={0.5}
        >
          - {name}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Setting default values for the props of DefaultReviewCard
DefaultReviewCard.defaultProps = {
  color: "transparent",
  image: "",
};

// Typechecking props for the DefaultReviewCard
DefaultReviewCard.propTypes = {
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
};

export default DefaultReviewCard;
