// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Contact from "./sections/Contact";
import InformationSection from "./sections/InformationSection";

// Routes
import routes from "routes";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import footerRoutes from "../../footer.routes";

// Images
import bgImage from "assets/images/about-us-drums-3.jpeg";
import missionImage from "assets/images/about-us-1.jpeg";
import Grid from "@mui/material/Grid";
import MKTypography from "../../components/MKTypography";
import AddressSection from "./sections/AddressSection";


function TaxDeductionInfo() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route:
            "https://www.paypal.com/donate/?hosted_button_id=VBUGKWLRRVUMW",
          label: "Donate",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
                                functions: {linearGradient, rgba},
                                palette: {gradients},
                              }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <InformationSection
          title="Tax Deduction Information"
          bodyTexts={
            [
              "We are so grateful for whatever contribution you can make, large or small.",
              "Donations are 100% tax deductible. MusicLight, Inc is approved by the IRS as a 501(c)(3) nonprofit organization, EIN 92-2622259.",
              "If you would like to donate via check, you can write a check payable to MusicLight Inc, and mail it to:",
            ]
          }
        />
        <AddressSection
          address={
            [
              "MusicLight",
              "3356 N 850 W",
              "Lehi, Utah 84043",
            ]
          }
        />
        <InformationSection
          paddingTop={0}
          bodyTexts={
            [
              "Auction Items:",
              "MusicLight is proudly sponsoring a fundraiser for Kenya Girls Chorale. We are holding a silent auction in which 100% of proceeds will go toward funding music therapy for girls who have experienced abuse, abandonment, and other traumas. We are currently seeking items valued at over $100.",
              "Tax donation receipts can be provided for all donations upon request. *Auction donations will be accepted now through December 2023. Please mail or deliver to the above address.",
              "Please contact us with any questions at info@musiclight.org.",
              "Thank you for your support!",
            ]
          }
        />
        <Contact/>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </>
  );
}

export default TaxDeductionInfo;
