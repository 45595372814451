// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import image from "assets/images/funny.jpg";
import MKTypography from "../../../components/MKTypography";
import {useMediaQuery} from "@mui/material";

function Information() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={7}>
            <Grid container justifyContent="flex-start">
              <MKTypography variant={isMobile ? "h2" : "h1"} mb={4}>
                Our Global Challenge
              </MKTypography>
              <MKTypography variant={isMobile ? "body2" : "body1"} mb={3}>
                It&apos;s estimated that{" "}
                <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                  one-in-three women
                </span>{" "}
                and{" "}
                <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                  one-in-five men{" "}
                </span>
                have an episode of{" "}
                <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                  major depression
                </span>{" "}
                in their life. Nearly 10% of Americans suffer from depression
                which is reaching epidemic levels.
              </MKTypography>
              <MKTypography variant={isMobile ? "body2" : "body1"} mb={3}>
                Depression is far more lethal than influenza for young people,
                yet there are no universal annual screenings. Gatekeepers, like
                teachers, parents, clergy, coaches and others, have little
                training so may not always recognize signs of depression among
                children and youth.
              </MKTypography>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3 } }}>
            <CenteredBlogCard
              image={image}
              title="Depression is a primary driver of Suicide"
              description="Imagine a world where the general cognitive capacity and healing ability has been raised for an entire generation.
Music Therapy & Education is an evidence-based treatment that helps with various disorders and cognitive development."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
