// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import MKTypography from "../../../components/MKTypography";

function AddressSection({ address }) {
  return (
    <MKBox component="section">
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            {address.map((text, index) => (
              <Grid container justifyContent="flex-start">
                <MKTypography key={index} variant="body1" mb={0}>
                  {text}
                </MKTypography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AddressSection;
