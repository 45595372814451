// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import eltonImage from "assets/images/team/elton.jpeg";
import neidersImage from "assets/images/team/mike-cindy-1.jpeg";
import milaImage from "assets/images/team/mila.jpeg"
import team3 from "assets/images/team-3.jpg";
import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

function Team() {
  return (
    <MKBox component="section">
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h1" mb={8}>
            Our team
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={6}>
              <HorizontalTeamCard
                image={eltonImage}
                name="Elton Luz"
                position={{ color: "info", label: "" }}
                description="Elton is an accomplished Executive, Music Producer, and Educator with over 100 million views on streaming platforms and more than two decades of experience in music production, educational content creation, and international training. He holds a Doctorate in Human Science with Extension from Harvard and a BA in Media Music from Brigham Young University. Elton has created cross-functional processes and development for distributing music technology and training courses for over 6,000 Music & Art Educators. He is dedicated to making a difference in the lives of individuals through music education and therapy and continues to make a positive impact in the world of music and beyond."
              />
            </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <HorizontalTeamCard
              image={neidersImage}
              name="Mike & Cindy Neider"
              position={{ color: "info", label: "" }}
              description="Michael and Cindy Neider recognized the need for educational app development while providing teacher training workshops in the Philippines and West Africa. App development allows educational content to be updated affordably and distributed efficiently, even in areas with limited Wi-Fi access. The Neiders' goal is to provide affordable, efficiently produced apps while also creating international job opportunities and uplifting local communities.
              Mike has a degree in Math Education and is a former President of MIRO Industries, as well as an attorney. Cindy taught school for nearly 30 years and was known for her original musicals and opera productions. Together, they share 10 children and 40 grandchildren. Currently, the Neiders are missionaries with the Tabernacle Choir at Temple Square serving as Global Participant Coordinators."
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <HorizontalTeamCard
              image={milaImage}
              name="Mila Luz"
              position={{ color: "info", label: "" }}
              description="Mila Luz is a talented composer and lyricist with a passion for words and storytelling. With experience in composing and writing lyrics for dozens of songs, as well as creating educational materials, musicals, and YouTube content, she is a versatile and accomplished creative professional. Mila leads the development of curriculum for MusicLight, bringing her expertise in music and education content creation to the forefront. Her dedication to creating engaging and impactful educational materials is unmatched, and her contributions to our team have been invaluable."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
