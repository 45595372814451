// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import EvidenceImageCard from "examples/Cards/BlogCards/EvidenceImageCard";

// Images
import neurotransmitters from "assets/images/illustrations/neurotransmitters.jpg";
import corpus from "assets/images/illustrations/corpus.jpg";
import executiveFunction from "assets/images/illustrations/executive-function.jpeg";
import memorySystems from "assets/images/illustrations/memory-systems.jpg";
import {useMediaQuery} from "@mui/material";

function Evidences() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant={isMobile ? "h2" : "h1"} mb={6}>
            Scientific Evidences
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{
          "@media (max-width:600px)": {
            maxWidth: "100%",
            width: "75%",
            margin: "auto"
          },
        }}>
          <Grid item xs={12} sm={6} lg={3}>
            <Card // card 1
              sx={{
                background: "transparent",
                boxShadow: "none",
                overflow: "visible",
              }}
            >
              <MKTypography variant={isMobile ? "h5" : "h4"} gutterBottom sx={{textAlign: 'center'}}>
                Positively affects{" "}
                <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                  Neurotransmitters
                </span>{" "}
                (Dopamine/Serotonin)
              </MKTypography>
              <EvidenceImageCard
                image={neurotransmitters}
              />
              <MKBox pt={2} pb={3}>
                <MKTypography variant={isMobile ? "body2" : "body1"} component="p" color="text" mb={3}>
                  Directly influence & regulate{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    focus
                  </span>
                  ,{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    mood
                  </span>
                  ,{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    motivation
                  </span>
                  , for {" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    anxiety
                  </span>
                  {" "}control,{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    depression
                  </span>
                  {" "}and{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    pain
                  </span>
                  {" "}suppression.
                </MKTypography>
              </MKBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card // card 2
              sx={{
                background: "transparent",
                boxShadow: "none",
                overflow: "visible",
              }}
            >
              <MKTypography variant={isMobile ? "h5" : "h4"} gutterBottom sx={{textAlign: 'center'}}>
                Musicians will always have a larger{" "}
                <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                  corpus callosum
                </span>
              </MKTypography>
              {isMobile ? null : <br />}
              <EvidenceImageCard
                image={corpus}
                title="Musicians will always have a larger corpus callosum"
                description="Allows messages to travel more efficiently through very creative pathways. Provides a high degree of plasticity."
              />
              <MKBox pt={2} pb={3}>
                <MKTypography variant={isMobile ? "body2" : "body1"} component="p" color="text" mb={3}>
                  Allows messages to travel more{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    efficiently
                  </span>{" "}
                  through{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    creative pathways
                  </span>
                  . Have a high degree of{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    plasticity
                  </span>
                  .
                </MKTypography>
              </MKBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card // card 3
              sx={{
                background: "transparent",
                boxShadow: "none",
                overflow: "visible",
              }}
            >
              <MKTypography variant={isMobile ? "h5" : "h4"} gutterBottom sx={{textAlign: 'center'}}>
                Higher levels of{" "}
                <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                  executive function
                </span>
              </MKTypography>
              {isMobile ? null : <br />}
              <EvidenceImageCard
                image={executiveFunction}
                title="Higher levels of executive function"
                description="Strengthens Logical, Strategic, Conceptual & Emotional Resilience with higher Activation and Focus."
              />
              <MKBox pt={2} pb={3}>
                <MKTypography variant={isMobile ? "body2" : "body1"} component="p" color="text" mb={3}>
                  Strengthens{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    Logical
                  </span>
                  ,{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    Strategic
                  </span>
                  {" "}and{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    Conceptual
                  </span>
                  {" "}brain regions, resulting in{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    Emotional Resilience
                  </span>
                  {" "}&{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    Problem Solving
                  </span>
                  {" "}capabilities.
                </MKTypography>
              </MKBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card // card 4
              sx={{
                background: "transparent",
                boxShadow: "none",
                overflow: "visible",
              }}
            >
              <MKTypography variant={isMobile ? "h5" : "h4"} gutterBottom sx={{textAlign: 'center'}}>
                Highly developed{" "}
                <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                  memory systems
                </span>
              </MKTypography>
              {isMobile ? null : <br />}
              <EvidenceImageCard
                image={memorySystems}
                title="Highly developed memory systems"
                description="TAGS to memories: Visual, Conceptual, Contextual & Emotional, pulling them from various sources."
              />
              <MKBox pt={2} pb={3}>
                <MKTypography variant={isMobile ? "body2" : "body1"} component="p" color="text" mb={3}>
                  TAGS to the memories:{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    Visual
                  </span>
                  ,{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    Contextual
                  </span>
                  ,{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    Logical
                  </span>{" "}
                  &{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    Emotional
                  </span>
                  . Pulling them from various{" "}
                  <span style={{ color: "#02C6F3", fontWeight: "bold" }}>
                    sources
                  </span>
                  .
                </MKTypography>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Evidences;
