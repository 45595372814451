// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
import {useMediaQuery} from "@mui/material";

function Testimonials() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
      <MKBox component="section" py={12}>
        <Container>
          <Grid
              container
              item
              xs={12}
              lg={6}
              justifyContent="center"
              sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant={isMobile ? "h2" : "h1"} mb={2}>
              Discover the <span style={{color: '#00BFFF'}}>Healing</span> Power of Music
            </MKTypography>
            <MKTypography variant="body1" color="text" mb={2}>
              Many schools, universities, companies, and governmental institutions
              love MusicLight&apos;s products and services.
            </MKTypography>
          </Grid>
          <Grid container spacing={3} sx={{  mt: { xs: 3, md: 8} }}>
            <Grid item xs={12} md={6} lg={4}>
              <DefaultReviewCard
                  color="light"
                  name="Julio Santana"
                  review="I cannot say enough positive things about the training program I recently completed. The curriculum was truly outstanding, covering everything I needed to know to become more productive and efficient with my students. Their instructors were incredible - their teaching style made the training super fun and engaging."
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DefaultReviewCard
                  color="info"
                  name="Omondi Kimani"
                  review="The impact on my students has been amazing. I can feel a new energy in their behavior and engagement during our lessons. Moreover, I recently decided to apply MusicLight's music therapy exercises and it has made a world of difference. My students are more focused, relaxed, and attentive. The exercises have also brought a sense of love and peace to our classroom. I am extremely grateful for MusicLight and the positive impact it has had on my teaching and my students. Thank you for providing such a valuable resource!"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DefaultReviewCard
                  color="light"
                  name="Janaina Landini"
                  review="As a social worker, I have been blown away by the impact of this workshop. At first, it was a new concept for me, but now I can confidently say that I am their newest and biggest fan and believer. I am so grateful to have participated, and to be able to share it with those in my care. I highly recommend it to anyone looking for a solution that works, especially for addressing mental health."
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  );
}

export default Testimonials;
