// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import MKTypography from "../../../components/MKTypography";
import Card from "@mui/material/Card";

function InformationSection({ title, bodyTexts, image = null, paddingTop = 12 }) {
  return (
    <MKBox component="section" pt={paddingTop}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={image ? 7 : 12}>
            <Grid container justifyContent="flex-start">
              <MKTypography variant="h1" mb={4}>
                {title}
              </MKTypography>
            </Grid>
            {bodyTexts.map((text, index) => (
              <Grid container justifyContent="flex-start">
              <MKTypography key={index} variant="body1" mb={index === bodyTexts.length - 1 ? 0 : 3}>
                  {text}
                </MKTypography>
              </Grid>
            ))}
          </Grid>
          {image && (
            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
              <Card>
                <MKBox
                  component="img"
                  src={image}
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  position="relative"
                  zIndex={1}
                />
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InformationSection;
