// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Team from "./sections/Team";
import Contact from "./sections/Contact";
import InformationSection from "./sections/InformationSection";
import ImagesSection from "./sections/ImagesSection";

// Routes
import routes from "routes";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import footerRoutes from "../../footer.routes";

// Images
import bgImage from "assets/images/about-us-drums-3.jpeg";
import missionImage from "assets/images/about-us-1.jpeg";
import {useRef, useState} from "react";
import * as emailjs from "@emailjs/browser";


function AboutUs() {
	const [alert, setAlert] = useState(null);
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_fh4nmu7', 'template_u58pho6', form.current, 'a2rJ_U9Rv8m5GdkiZ')
			.then((result) => {
				setAlert({
					type: 'success',
					message: 'Message sent successfully. We will be in touch shortly. Thank you!'
				});
			}, (error) => {
				setAlert({
					type: 'error', message: (
						<div>
							Failed to send message. Please email <a href="mailto:info@musiclight.org">info@musiclight.org</a> or try
							again later.
						</div>
					),
				});
			});
	};

	return (
		<>
			<DefaultNavbar
				routes={routes}
				action={{
					type: "external",
					route:
						"https://www.paypal.com/donate/?hosted_button_id=VBUGKWLRRVUMW",
					label: "Donate",
					color: "default",
				}}
				transparent
				light
			/>
			<MKBox bgColor="white">
				<MKBox
					minHeight="25rem"
					width="100%"
					sx={{
						backgroundImage: ({
							                  functions: {linearGradient, rgba},
							                  palette: {gradients},
						                  }) =>
							`${linearGradient(
								rgba(gradients.dark.main, 0.8),
								rgba(gradients.dark.state, 0.8)
							)}, url(${bgImage})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						display: "grid",
						placeItems: "center",
					}}
				/>
				<InformationSection
					title="Our mission and purpose"
					bodyTexts={
						[
							"Our mission at MusicLight is to provide accessible and innovative music education and therapy programs to individuals of all ages and backgrounds, with a special focus on those who suffer from mental health issues such as depression, anxiety, and learning disorders. We believe that music has the power to heal, uplift, and transform lives, and that every individual deserves the opportunity to reach their full potential.",
							"Through our child-youth-centered programs, we aim to empower individuals to face today's challenges with courage and confidence, becoming active thinkers, problem-solvers, and community leaders. We are dedicated to breaking the stigma surrounding mental health and providing scientifically validated interventions that effectively address mental health issues while enhancing cognitive development.",
							"At MusicLight, we strive to create a more compassionate, resilient, and connected world where individuals can thrive. By supporting our cause, you are contributing to the betterment of society as a whole, and we invite you to join us in this journey toward a brighter future."
						]
					}
					image={missionImage}
				/>
				<InformationSection
					title="History and background"
					bodyTexts={
						[
							"Music Light, Inc. was founded with the vision of creating a world where the general cognitive capacity and healing ability has been raised for an entire generation. The organization was established by a group of music educators and therapists who recognized the immense potential of music in promoting mental health and cognitive function.",
							"With two decades of experience in music education and therapy, Music Light, Inc. has dedicated itself to preventing and treating mental illnesses such as depression, anxiety, ADHD, learning disorders, and more. The organization firmly believes that music education and therapy can play a vital role in helping communities with cognitive function improvement and skill development, thereby increasing opportunities and reducing disparities.",
							"Music Therapy & Education is an evidence-based treatment that has been proven to help with a variety of disorders, including anxiety, depression, autism, substance abuse, ADHD, learning disorders, literacy gaps, and even Alzheimer's disease. Music has the power to help individuals improve memory, problem-solving skills, coping strategies, and more.",
							"At Music Light, Inc., we have developed innovative and child-youth-centered music education and therapy programs that aim to reduce stress, build self-esteem, and enhance cognitive function. We believe that every individual deserves the opportunity to reach their full potential, and our programs are designed to empower individuals to face today's challenges with courage and confidence, becoming active thinkers, problem-solvers, and community leaders.",
							"Over the years, Music Light, Inc. has made a significant impact in the lives of many individuals, and we continue to strive towards creating a world where mental health is prioritized, and every individual has access to the benefits of music education and therapy."
						]
					}
				/>
				<ImagesSection/>
				<Team/>
				<Contact/>
			</MKBox>
			<MKBox pt={6} px={1} mt={6}>
				<DefaultFooter content={footerRoutes}/>
			</MKBox>
		</>
	);
}

export default AboutUs;
