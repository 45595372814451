// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


import {Link} from "react-router-dom";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Landing page sections
import Donate from "pages/LandingPage/sections/Donate";
import Evidences from "pages/LandingPage/sections/Evidences";
import Information from "pages/LandingPage/sections/Information";
import Impact from "pages/LandingPage/sections/Impact";
import Newsletter from "pages/LandingPage/sections/Newsletter";
import Testimonials from "pages/LandingPage/sections/Testimonials";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-teens-smiling.jpeg";
import InformationSection from "../AboutUs/sections/InformationSection";
import missionImage from "assets/images/about-us-1.jpeg";

import LaunchIcon from '@mui/icons-material/Launch';

function LandingPage() {
	return (
		<>
			<DefaultNavbar
				routes={routes}
				action={{
					type: "external",
					route:
						"https://www.paypal.com/donate/?hosted_button_id=VBUGKWLRRVUMW",
					label: "Donate",
					color: "default",
				}}
				transparent
				light
			/>
			<MKBox
				minHeight="75vh"
				width="100%"
				sx={{
					backgroundImage: ({
						                  functions: {linearGradient, rgba},
						                  palette: {gradients},
					                  }) =>
						`${linearGradient(
							rgba(gradients.dark.main, 0.8),
							rgba(gradients.dark.state, 0.8)
						)}, url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "top",
					display: "grid",
					placeItems: "center",
				}}
			>
				<Container>
					<Grid container direction="column" alignItems="center">
						<MKTypography
							variant="h4"
							color="white"
							opacity={0.8}
							mt={{xs: 16, md: 10}} // add 2 units of margin-top on screens smaller than md breakpoint
							mb={3}
							textAlign="center"
						>
							Overcoming depression, anxiety, ADHD and learning disorders
						</MKTypography>
						<MKTypography
							variant="h1"
							color="white"
							textAlign="center"
							sx={({breakpoints, typography: {size}}) => ({
								[breakpoints.down("md")]: {
									fontSize: size["3xl"],
								},
							})}
							mb={7}
						>
							Transform lives with us.
						</MKTypography>
						<Grid container md={8} justifyContent="center" alignItems="center" spacing={3}>
							<Grid item xs={12} md={6} sx={{textAlign: "center"}}>
								<MKBox
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									<MKButton
										color="default"
										href="https://www.paypal.com/donate/?hosted_button_id=VBUGKWLRRVUMW"
										target="_blank"
										size="large"
										sx={{
											color: ({palette: {dark}}) => dark.main,
											"&:hover": {
												backgroundColor: ({palette: {info}}) => info.main,
												color: ({palette: {light}}) => light.main,
											},
											marginRight: '1rem'
										}}
									>
										Donate
									</MKButton>
									<MKButton
										color="default"
										size="large"
										sx={{
											color: ({palette: {dark}}) => dark.main,
											"&:hover": {
												backgroundColor: ({palette: {info}}) => info.main,
												color: ({palette: {light}}) => light.main,
											},
										}}
									>
										<Link
											to="/contact-us"
											style={{textDecoration: "none", color: "inherit"}}
										>
											Volunteer
										</Link>
									</MKButton>
								</MKBox>
								<MKBox display="flex"
											 >
									<Grid item xs={12} sx={{textAlign: "center"}}>
										<MKTypography component="a" variant="button" color="white" href="/tax-deduction-info">
											Tax Deduction Info <LaunchIcon />
										</MKTypography>
									</Grid>
									<Grid item xs={0}>
									</Grid>
								</MKBox>
								<MKBox display="flex" justifyContent="center" alignItems="center" mt={7}>
									<MKTypography component="a" variant="h2" color="white" target="_blank"
									              href="https://www.facebook.com/profile.php?id=100091905194851&mibextid=LQQJ4d" mr={3}>
										<i className="fab fa-facebook"/>
									</MKTypography>
									<MKTypography component="a" variant="h2" color="white" target="_blank"
									              href="https://instagram.com/musiclightofficial?igshid=YmMyMTA2M2Y=" mr={3}>
										<i className="fab fa-instagram"/>
									</MKTypography>
									<MKTypography component="a" variant="h2" color="white" target="_blank"
									              href="https://www.linkedin.com/company/musiclight-inc/" mr={3}>
										<i className="fab fa-linkedin"/>
									</MKTypography>
								</MKBox>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</MKBox>
			<InformationSection
				title="Our mission and purpose"
				bodyTexts={
					[
						"MusicLight provides accessible music education and therapy to heal, uplift, and transform lives.",
						"We break the stigma surrounding mental health by offering scientifically validated interventions that improve mental health and enhance socialization, cognitive development, and joy.",
						"Our mission is to empower individuals to become active thinkers, problem-solvers, and community leaders with courage and confidence."
					]
				}
				image={missionImage}
			/>
			<Impact/>
			<Information/>
			<Evidences/>
			<Testimonials/>
			<Donate/>
			<Newsletter/>
			<MKBox pt={6} px={1} mt={6}>
				<DefaultFooter content={footerRoutes}/>
			</MKBox>
		</>
	);
}

export default LandingPage;
