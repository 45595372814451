// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "../../../components/MKTypography";
import {useMediaQuery} from "@mui/material";

function Impact() {
  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <MKTypography variant="h1" mt={8} mb={3} sx={{ textAlign: "center" }}>
          Global Impact
        </MKTypography>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={600000}
              separator=","
              suffix="+"
              title="Kids and Teens"
              description="Reached in countries and languages throughout the world"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={6000}
              separator=","
              suffix="+"
              title="Educators"
              description="That meet quality standards required by governments and schools"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={5}
              title="Countries"
              description="Actively engaged in transforming lives through music"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Impact;
