// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function CenteredBlogCard({image, title, description}) {
	return (
		<Card>
			<MKBox position="relative" borderRadius="lg" mx={2} mt={3} height={220}>
				<iframe width="100%" height="100%" src="https://www.youtube.com/embed/yYN6TSHZjLY"
				        title="YouTube video player" frameBorder="0"
				        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				        allowFullScreen/>
			</MKBox>
			<MKBox p={3} textAlign="center">
				<MKTypography
					display="inline"
					variant="h5"
					textTransform="capitalize"
					fontWeight="regular"
				>
					{title}
				</MKTypography>
				<MKBox mt={1} mb={3}>
					<MKTypography variant="body2" component="p" color="text">
						{description}
					</MKTypography>
				</MKBox>
			</MKBox>
		</Card>
	);
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	action: PropTypes.shape({
		type: PropTypes.oneOf(["external", "internal"]).isRequired,
		route: PropTypes.string.isRequired,
		color: PropTypes.oneOf([
			"primary",
			"secondary",
			"info",
			"success",
			"warning",
			"error",
			"dark",
			"light",
		]),
		label: PropTypes.string.isRequired,
	}).isRequired,
};

export default CenteredBlogCard;
