/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

function Newsletter() {
  return (
      <MKBox component="section" pt={6} mt={6} mb={6}>
        <Container>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
                item
                sx={12}
                md={6}
                sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}
            >
              <MKTypography variant="h4">
                Be the first to see the news
              </MKTypography>
              <MKTypography variant="body1" color="text" mb={3}>
                Get early insights about our exciting updates and opportunities to
                volunteer
              </MKTypography>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <MKInput type="email" label="Email Here..." fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <MKButton
                      variant="gradient"
                      color="info"
                      sx={{ height: "100%" }}
                  >
                    Subscribe
                  </MKButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  );
}

export default Newsletter;
