/* eslint-disable react/jsx-no-duplicate-props */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Images
import donate_qr from "assets/images/donate-qr.jpeg";
import {Icon, useMediaQuery} from "@mui/material";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

function Donate() {
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

	return (
		<MKBox component="section">
			<Container>
				<MKTypography variant={isMobile ? "h2" : "h1"} mt={8} mb={3} sx={{textAlign: "center"}}>
					Support MusicLight with a <span style={{color: "#02C6F3", fontWeight: "bold"}}>Donation</span> Today!
				</MKTypography>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<MKTypography variant={isMobile ? "body2" : "body1"} mb={5} sx={{textAlign: "center", width: "60%"}}>
						Scan the QR code to donate to MusicLight, a non-profit providing music education to underprivileged
						communities. Your contribution helps make a difference in the lives of young people. Thank you for your
						support!
					</MKTypography>
				</div>
				<Grid container justifyContent="center" sx={{textAlign: "center"}}>
					<Grid item xs={12} md={3}>
						<MKBox position="relative">
							<a href="https://www.paypal.com/donate/?hosted_button_id=VBUGKWLRRVUMW" target="_blank"
							   rel="noreferrer">
								<MKBox
									component="img"
									src={donate_qr}
									alt="Donate QR"
									width="66%"
									sx={{
										filter: 'grayscale(100%)',
										boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
										'&:hover': {
											filter: 'none',
											animation: 'wiggle 0.3s ease-in-out',
											boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.6)',
										},
										transition: 'all 0.3s ease-in-out',
										marginBottom: '10px',
									}}
								/>
							</a>
						</MKBox>
						<MKButton
							href="https://www.paypal.com/donate/?hosted_button_id=VBUGKWLRRVUMW"
							target="_blank"
							color="info"
							size="large"
						>
							Donate Now&nbsp;<Icon><VolunteerActivismIcon/></Icon>
						</MKButton>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default Donate;
