import {useRef, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import * as emailjs from "@emailjs/browser";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";

function ContactUs() {
	const [alert, setAlert] = useState(null);
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_fh4nmu7', 'template_u58pho6', form.current, 'a2rJ_U9Rv8m5GdkiZ')
			.then((result) => {
				setAlert({
					type: 'success',
					message: 'Message sent successfully. We will be in touch shortly. Thank you!'
				});
			}, (error) => {
				setAlert({
					type: 'error', message: (
						<div>
							Failed to send message. Please email <a href="mailto:info@musiclight.org">info@musiclight.org</a> or try
							again later.
						</div>
					),
				});
			});
	};

	return (
		<>
			<MKBox position="relative" top="1.45rem" width="100%">
				<DefaultNavbar
					routes={routes}
					action={{
						type: "external",
						route:
							"https://www.paypal.com/donate/?hosted_button_id=VBUGKWLRRVUMW",
						label: "Donate",
						color: "info",
					}}
					transparent
					dark
				/>
			</MKBox>
			<Grid container spacing={3} alignItems="center">
				<Grid item xs={12} xl={6}>
					<MKBox
						display={{xs: "none", xl: "flex"}}
						width="calc(100% - 2rem)"
						height="calc(100vh - 2rem)"
						borderRadius="lg"
						ml={2}
						mt={20}
						sx={{backgroundImage: `url(${bgImage})`}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={10}
					md={7}
					lg={6}
					xl={4}
					ml={{xs: "auto", xl: 6}}
					mr={{xs: "auto", xl: 6}}
				>
					<MKBox
						bgColor="white"
						borderRadius="xl"
						shadow="lg"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						mt={{xs: 20, sm: 18, md: 20}}
						mb={{xs: 20, sm: 18, md: 20}}
						mx={3}
					>
						<MKBox
							variant="gradient"
							bgColor="info"
							coloredShadow="info"
							borderRadius="lg"
							p={2}
							mx={2}
							mt={-3}
						>
							<MKTypography variant="h3" color="white">
								Contact us
							</MKTypography>
						</MKBox>
						<MKBox p={3}>
							{alert && (
								<MKAlert color={alert.type} onClose={() => setAlert(null)}>
									{alert.message}
								</MKAlert>
							)}
							<MKTypography variant="body1" color="text" mb={3}>
								For further questions, including volunteer opportunities, please
								email <a href="mailto:info@musiclight.org">info@musiclight.org</a> or use the contact form below.
							</MKTypography>
							<MKBox width="100%" component="form" method="post" ref={form} onSubmit={sendEmail}>
								<Grid container spacing={3}>
									<Grid item xs={12} md={6}>
										<MKInput
											variant="standard"
											label="First"
											name="first_name"
											InputLabelProps={{shrink: true}}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<MKInput
											variant="standard"
											label="Last"
											name="last_name"
											InputLabelProps={{shrink: true}}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<MKInput
											type="email"
											variant="standard"
											label="Email"
											name="email"
											InputLabelProps={{shrink: true}}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<MKInput
											type="tel"
											variant="standard"
											label="Phone"
											name="phone"
											InputLabelProps={{shrink: true}}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<MKInput
											variant="standard"
											label="How can we help you?"
											name="message"
											InputLabelProps={{shrink: true}}
											multiline
											fullWidth
											rows={6}
										/>
									</Grid>
								</Grid>
								<Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
									<MKButton type="submit" variant="gradient" color="info">
										Send Message
									</MKButton>
								</Grid>
							</MKBox>
						</MKBox>
					</MKBox>
				</Grid>
			</Grid>
			<MKBox pt={6} px={1} mt={6}>
				<DefaultFooter content={footerRoutes}/>
			</MKBox>
		</>
	);
}

export default ContactUs;
