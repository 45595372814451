// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import footerRoutes from "../../footer.routes";

// Images
import bgImage from "assets/images/about-us-drums-3.jpeg";
import Grid from "@mui/material/Grid";
import called_to_serve_qr from "../../assets/images/called-to-serve-qr.svg";
import new_degree_qr from "../../assets/images/new-degree-qr.svg";


function QRPage() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route:
            "https://www.paypal.com/donate/?hosted_button_id=VBUGKWLRRVUMW",
          label: "Donate",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({
                                functions: {linearGradient, rgba},
                                palette: {gradients},
                              }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
      </MKBox>
      <Grid container justifyContent="center" mt={8} sx={{textAlign: "center"}}>
        <Grid item xs={12} md={4}>
          <MKBox position="relative">
            <a href="https://drive.google.com/file/d/1Q57Rf0kOXBa9sFv8xtuVs19r5yRbOFzo/view" target="_blank"
               rel="noreferrer">
              <MKBox
                component="img"
                src={called_to_serve_qr}
                alt="Donate QR"
                width="66%"
                sx={{
                  filter: 'grayscale(100%)',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                  '&:hover': {
                    filter: 'none',
                    animation: 'wiggle 0.3s ease-in-out',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.6)',
                  },
                  transition: 'all 0.3s ease-in-out',
                  marginBottom: '10px',
                }}
              />
            </a>
          </MKBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <MKBox position="relative">
            <a href="https://drive.google.com/file/d/1ynaNqw_XspX8jv9lxKg8nLCQoee9yYqy/view?usp=sharing" target="_blank"
               rel="noreferrer">
              <MKBox
                component="img"
                src={new_degree_qr}
                alt="Donate QR"
                width="66%"
                sx={{
                  filter: 'grayscale(100%)',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                  '&:hover': {
                    filter: 'none',
                    animation: 'wiggle 0.3s ease-in-out',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.6)',
                  },
                  transition: 'all 0.3s ease-in-out',
                  marginBottom: '10px',
                }}
              />
            </a>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </>
  );
}

export default QRPage;
