import MKBox from "../../../components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import EvidenceImageCard from "../../../examples/Cards/BlogCards/EvidenceImageCard";
import studioImage from "assets/images/about-us-studio.jpeg";
import choirImage from "assets/images/about-us-choir.jpeg";
import drumImageLong from "assets/images/about-us-drums-2.jpeg";

function ImagesSection() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <Card // card 1
              sx={{
                background: "transparent",
                boxShadow: "none",
                overflow: "visible",
              }}
            >
              <EvidenceImageCard
                image={studioImage}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card // card 2
              sx={{
                background: "transparent",
                boxShadow: "none",
                overflow: "visible",
              }}
            >
              <EvidenceImageCard
                image={choirImage}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card // card 4
              sx={{
                background: "transparent",
                boxShadow: "none",
                overflow: "visible",
              }}
            >
              <EvidenceImageCard
                image={drumImageLong}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ImagesSection;